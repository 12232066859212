import req from "../methods/req"
import method from '../methods/method'

export const costLaunchList = (data) => method.get_list({ url: '/manage/crm.cost/costLaunchList', ...data })
export const costGrantList = (data) => method.get_list({ url: '/manage/crm.cost/costGrantList', ...data })
export const getContractTemplateList = (data) => method.get_list({ url: '/manage/crm.contract/getContractTemplateList', ...data })
export const getSuperviseList = (data) => method.get_list({ url: '/manage/crm.supervise/getSuperviseList', ...data })
export const getAuthUser = (data) => method.get_list({ url: '/manage/crm.supervise/getAuthUser', ...data })
export const saveAuthUser = (data) => req({ url: '/manage/crm.supervise/saveAuthUser', ...data })
export const removeUser = (data) => req({ url: '/manage/crm.supervise/removeUser', ...data })
// manage/crm.supervise/delSupervise/
export const delSupervise = (data) => req({ url: '/manage/crm.supervise/delSupervise', ...data })
//关联投放  
export const getPutInSelect = (data) => req({ url: '/manage/crm.cost/getPutInSelect', ...data })
//获取物料 
export const getGoodsData = (data) => req({ url: '/manage/crm.supervise/getGoodsData', ...data })
//获取品项
export const getGoodsSelect = (data) => method.get_list({ url: '/manage/crm.common/getGoodsSelect', ...data })
//添加监管 manage/crm.supervise/saveSupervise
export const saveSupervise = (data) => req({ url: '/manage/crm.supervise/saveSupervise', ...data })
//监管详情 
export const getSuperviseDetail = (data) => req({ url: '/manage/crm.supervise/getSuperviseDetail', ...data })
export const constLaunchDetails = (data) => req({ url: '/manage/crm.cost/constLaunchDetails', ...data })
export const costAddList = (data) => method.get_list({ url: '/manage/crm.cost/costAddList', ...data })
export const costAddDetail = (data) => req({ url: '/manage/crm.cost/costAddDetail', ...data })

export const unsettlementList = (data) => method.get_list({ url: '/manage/crm.settlement/unsettlementList', ...data })
export const settlementList = (data) => method.get_list({ url: '/manage/crm.settlement/settlementList', ...data })
export const abnormalSettlementList = (data) => method.get_list({ url: '/manage/crm.settlement/abnormalSettlementList', ...data })

export const contractList = (data) => method.get_list({ url: '/manage/crm.contract/contractList', ...data })

export const delContractTemplate = (data) => req({ url: '/manage/crm.contract/delContractTemplate', ...data })
export const contractTemplateDeteail = (data) => req({ url: '/manage/crm.contract/contractTemplateDeteail', ...data })
export const saveContractTempate = (data) => req({ url: '/manage/crm.contract/saveContractTempate', ...data })

export const changeContractStauts = (data) => req({ url: '/manage/crm.contract/changeContractStauts', ...data })
export const contractDetail = (data) => req({ url: '/manage/crm.contract/contractDetail', ...data })

export const settlementDetail = (data) => req({ url: '/manage/crm.settlement/settlementDetail', ...data })
export const beginSettle = (data) => req({ url: '/manage/crm.settlement/beginSettle', ...data })
export const finishSettle = (data) => req({ url: '/manage/crm.settlement/finishSettle', ...data })
export const getCostType = (data) => req({ url: '/manage/crm.approval/getCostType', ...data })

export const delContract = (data) => req({ url: '/manage/crm.contract/delContract', ...data })
export const getConfig = (data) => req({ url: '/manage/crm.cost/getConfig', ...data })
export const saveConfig = (data) => req({ url: '/manage/crm.cost/saveConfig', ...data })
export const genarateApproval = (data) => req({ url: '/manage/crm.settlement/genarateApproval', ...data })

// 终止合约
export const endContract = (data) => req({ url: '/manage/crm.contract/endContract', ...data })
// 撤销合约
export const withdrawContract = (data) => req({ url: '/manage/crm.contract/withdrawContract', ...data })
// 恢复合约
export const recoveryContract = (data) => req({ url: '/manage/crm.contract/recoveryContract', ...data })


// 获取品项不分页
export const getGoods = function (data) {
	return req({
		url: '/manage/crm.goods/getGoods',
		...data
	})
}

// 获取投放位置
export const getType = function (data) {
	return req({
		url: '/manage/crm.goods/getType',
		...data
	})
}

// 保存投放
export const editLaunch = function (data) {
	return req({
		url: '/manage/crm.cost/editLaunch',
		...data
	})
}

// 获取上级经销商
export const getSuperior = function (data) {
	return req({
		url: '/manage/crm.cost/getSuperior',
		...data
	})
}

// 获取发放详情
export const getGrantDetail = function (data) {
	return req({
		url: '/manage/crm.cost/getGrantDetail',
		...data
	})
}
// 发放编辑
export const editGrant = function (data) {
	return req({
		url: '/manage/crm.cost/editGrant',
		...data
	})
}
// 删除订单
export const delOrder = function (data) {
	return req({
		url: '/manage/crm.order_v2/delOrder',
		...data
	})
}

//费用发放管理中的合约列表
export const getContractList = (data) => method.get_list({ url: '/manage/crm.cost/getContractList', ...data })




//费用发放列表部分页
export const getGrantList = function (data) {
	return req({
		url: '/manage/crm.cost/getGrantList',
		...data
	})
}


// 费用明细报表
export const costDetailed = function({
    _this,
    data = {}
}) {
    return method.get_list({
        _this,
        url: '/manage/crm.report/costDetailed',
        data
    })
}

//费用明细报表顶部统计
export const costDetailedCensus = function (data) {
	return req({
		url: '/manage/crm.report/costDetailedCensus',
		...data,
	})
}

//费用明细导出Excel
export const costDetailedExport = function (data) {
	return req({
		url: '/manage/crm.report/costDetailedExport',
		...data,
	})
}

//结案数据推送记录列表
export const getSalesList = function({
    _this,
    data = {}
}) {
    return method.get_list({
        _this,
        url: '/manage/crm.promotion/getList',
        data
    })
}

//结案数据推送记录详情列表
export const getClosure = function (data) {
	return req({
		url: '/manage/crm.promotion/getClosure',
		...data,
	})
}
// export const getClosure = function({
//     _this,
//     data = {}
// }) {
//     return method.get_list({
//         _this,
//         url: '/manage/crm.promotion/getClosure',
//         data
//     })
// }

//结案数据推送记录详情导出Excel
export const closureExport = function (data) {
	return req({
		url: '/manage/crm.promotion/closureExport',
		...data,
	})
}

//结案数据推送记录月份数据
export const getPushMonth = function (data) {
	return req({
		url: '/manage/crm.promotion/getPushMonth',
		...data,
	})
}


//结案数据推送（单个活动）
export const pushPromotion = function (data) {
	return req({
		url: '/manage/crm.promotion/pushPromotion',
		...data,
	})
}

//结案数据推送（所有数据）
export const pushAll = function (data) {
	return req({
		url: '/manage/crm.promotion/pushAll',
		...data,
	})
}

//结案数据统计
export const getClosureCensus = function (data) {
	return req({
		url: '/manage/crm.promotion/getClosureCensus',
		...data,
	})
}




//费用品相分组
// export const itemGroupList = (data) => method.get_list({ url: '/manage/cost.item_group/itemGroupList', ...data })

export const itemGroupList = function (data) {
	return req({
		url: '/manage/cost.item_group/itemGroupList',
		...data,
	})
}
//费用品相分组新增/编辑
export const modifyItemGroup = function (data) {
	return req({
		url: '/manage/cost.item_group/modifyItemGroup',
		...data,
	})
}
//费用品相分组启用禁用
export const changeItemGroup = function (data) {
	return req({
		url: '/manage/cost.item_group/changeItemGroup',
		...data,
	})
}
//费用品相分组删除
export const delItemGroup = function (data) {
	return req({
		url: '/manage/cost.item_group/delItemGroup',
		...data,
	})
}

//费用品相新增/编辑
export const modifyItem = function (data) {
	return req({
		url: '/manage/cost.item_group/modifyItem',
		...data,
	})
}




// //费用品相列表删除
export const delItem = function (data) {
	return req({
		url: '/manage/cost.item_group/delItem',
		...data,
	})
}


// 手动推送
export const manualPush = function (data) {
	return req({
		url: '/manage/crm.promotion/manualPush',
		...data,
	})
}

// 获取促销活动ID
export const getPromotionIds = function (data) {
	return req({
		url: '/manage/crm.promotion/getPromotionIds',
		...data,
	})
}