<template>
    <div>
        <PopTableList
            :form_data_seo="form_data_seo_pop"
            :form_data="form_data_pop"
            :zIndex="2000"
            :columns="pop_columns"
            :visible.sync="user_visible"
            :rowSelection="rowSelection"
            :get_table_list="get_table_list"
            :submit_preprocessing="submit_preprocessing"
            :slot_table_list="['department', 'user_status']"
            @submit="submit"
            rowKey="id"
        >
            <template slot="department" slot-scope="data">
                <span>{{ data.record.department_join.department_join }}</span>
            </template>
            <template slot="user_status" slot-scope="data">
                <span v-if="data.text == -1">离职</span>
                <span v-else-if="data.text == 1">在职</span>
            </template>
            <div slot="pop_footer">
                已选择{{ selectedRowKeys.length || 0 }}人
            </div>
        </PopTableList>
    </div>
</template>

<script>
import PopTableList from './PopTableList'
import { getUserList } from "@/api/personnel";
import props_pop from './props/pop'
import table_pop from './props/table'
const columns = [
    {
        title: "姓名",
        dataIndex: "username"
    },
    {
        title: "部门",
        dataIndex: "department",
        scopedSlots: {
            customRender: "department"
        }
    },
    {
        title: "岗位职务",
        dataIndex: "role_name"
    },
    {
        title: "状态",
        dataIndex: "user_status",
        scopedSlots: {
            customRender: "user_status"
        }
    }
]

export default {
    name: 'SelectUser',
    components: {
        PopTableList,
    },
    props: {
        ...props_pop,
        ...table_pop,
        select_type: {
            type: String,
            default: 'radio'
        },		
    },

    data() {
        return {
            user_visible: false,
            get_table_list: getUserList,
            pop_columns: columns,
            submit_preprocessing: {
                array_to_string: ['department_id','role_id']
            },
            form_data_pop: {
                title: '请选择人员'
            },
            form_data_seo_pop: {
                ...this.$config.form_data_seo,
                list: [
                    {
                        type: "tree-select",
                        name: "department_id",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "tree-select",
                        name: "role_id",
                        title: "岗位职务",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    // {
                    //     type: "select",
                    //     name: "role_id",
                    //     title: "岗位职务",
                    //     mode: "default",
                    //     options: {},
                    //     list: []
                    // },
                    {
                        type: "text",
                        name: "keyword",
                        title: "姓名",
                        options: {}
                    }
                ]
            },
            select_user: {

            },
            select_user_list: [],
            selectedRowKeys: []
        }
    },
    computed: {
        rowSelection() {
            return {
                onChange: (selectedRowKeys, selectedRows) => {
                    this.selectedRowKeys = selectedRowKeys;
                    if (this.select_type == 'radio') {
                        this.select_user = selectedRows[0];
                    } else {
                        const map = new Map()
                        this.select_user_list = [...this.select_user_list, ...selectedRows].filter(item => {
                            return !map.has(item.id) && map.set(item.id, item.id) && this.selectedRowKeys.indexOf(item.id) > -1
                        });
                    }
                },
                selectedRowKeys: this.selectedRowKeys,
                type: this.select_type,
            };
        },
    },
    watch: {
        visible(new_value) {
            this.user_visible = new_value
        },
        user_visible(new_value) {
            this.$emit('update:visible', new_value)
        },
    },
    created() {
        Promise.all([this.$method.get_department(), this.$method.get_role_tree()]).then(res => {
            this.form_data_seo_pop.list.forEach(item => {
                if (item.name == "department_id") {
                    item.treeData = res[0];
                }
                if (item.name == "role_id") {
                    // item.list = [...this.$config.status_all, ...res[1]];
                    item.treeData = res[1];
                }
            });
        })
    },
    methods: {
        submit() {
            this.$emit('submit', {
                data: this.select_type == 'radio' ? this.select_user : this.select_user_list
            })
			this.selectedRowKeys = [];
        }
    }
}
</script>