<template>
    <div>
        <TableList
            :form_data_seo.sync="form_data_seo"
            :columns="columns"
            :get_table_list="this.get_table_list"
            :slot_table_list="['operation']"
            :submit_preprocessing="submit_preprocessing"
            ref="list"
        >
            <template slot="left_btn">
                <a-button
                    class="float_left"
                    v-auth="'change'"
                    @click="add()"
                    type="primary"
                    >添加人员</a-button
                >
            </template>

            <template slot="right_btn">
                <export-table
                    v-if="this.$store.state.page_auth.export"
                    url="/manage/crm.supervise/exportAuthUser"
                ></export-table>
            </template>

            <template slot="operation" slot-scope="data">
                <a-popconfirm
                    title="您确定要删除吗?"
                    v-auth="'delete'"
                    @confirm="del(data.record)"
                >
                    <a>删除</a>
                </a-popconfirm>
            </template>
        </TableList>
        <SelectUser @submit="add_user" :visible.sync="visible"></SelectUser>
    </div>
</template>

<script>
// @ is an alias to /src
import EditPop from "@/components/EditPop";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import { getAuthUser, saveAuthUser, removeUser } from "@/api/cost";
import SelectUser from "@/components/SelectUser";
let columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "姓名",
        dataIndex: "username",
    },
    {
        title: "添加时间",
        dataIndex: "create_time",
    },
    {
        title: "操作",
        scopedSlots: {
            customRender: "operation"
        }
    },
];

export default {
    name: "Index",
    components: {
        EditPop,
        Form,
        SelectUser,
        TableList
    },
    data() {
        return {
            get_table_list: getAuthUser,
            submit_preprocessing: {
                array_to_string: ['department_id']
            },
            columns,
            visible: false,
            form_data_seo: {
                list: [
                    {
                        type: "tree-select",
                        name: "department_id",
                        title: "部门",
                        options: {},
                        treeData: [],
                        multiple: true
                    },
                    {
                        type: "text",
                        name: "keyword",
                        title: "监管人",
                        placeholder: '输入工号、姓名、手机号',
                        options: {

                        }
                    },

                ],
                ...this.$config.form_data_seo
            },

        };
    },
    async created() {
        this.$method.get_department().then(res => {
            this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'department_id', 'treeData', res)
        });
    },
    methods: {
        add() {
            this.visible = true;
        },
        del(data) {
            removeUser({
                data: {
                    id: data.id
                },
                info: true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
        add_user({ data }) {
            saveAuthUser({
                data: {
                    uid: data.id
                },
                info: true,
            }).then(res => {
                this.$refs.list.get_list();
            })
        },
    }
};
</script>

<style lang="less">
@import url("../../assets/less/app.less");
</style>